import Navbar from "../../components/Navbar";
import NavBarMobile from "../../components/NavBarMobile";
import CmsPage from "../../components/CmsPage";

import { loadPage } from '../../lib/api-lib'

const paths = [];

function Page({ structure }) {
  return (
    <>
      <Navbar navigation={structure.navigation} />
      <NavBarMobile navigation={structure.navigation} />
      {typeof structure.page !== 'undefined' ? 
        <CmsPage structure={structure} />
      : ''}
    </>
  )
}

export async function getStaticProps(context) {
  var pageSlugs = context.params.slug
  let queryParams = '';
  if(!isNaN(pageSlugs[pageSlugs.length-1])) {
    let id = pageSlugs.pop()
    queryParams = '?id=' + id;
  }
  // console.log(pageSlugs.join('/')+queryParams);
  const data = await loadPage(pageSlugs.join('/')+queryParams);
  return {
    props: {
      structure: data,
    },
    revalidate: 1,
  };
}

export async function getStaticPaths() {

    // // NAVIGATION
    // const json = await loadPage();
    // json.navigation.map(function(link) {
    //   recursiveStaticPathGeneration(link);
    // })

    // // COLLECTIONS
    // const json2 = await loadPage('tours-packages/tours');
    // getCollectionPaths(json2, 'tours-packages/tours');

    // const json3 = await loadPage('tours-packages/tour-packages/adventure');
    // getCollectionPaths(json3, 'tours-packages/tour-packages/adventure');

    // const json4 = await loadPage('tours-packages/tour-packages/nature');
    // getCollectionPaths(json4, 'tours-packages/tour-packages/nature');

    // const json5 = await loadPage('tours-packages/tour-packages/culture');
    // getCollectionPaths(json5, 'tours-packages/tour-packages/culture');

    // const json6 = await loadPage('the-coral-reef-house/tours-coral-reef-house');
    // getCollectionPaths(json6, 'the-coral-reef-house/tours-coral-reef-house');

    // // FIXED
    // paths.push({ params: {'slug': ['contact-us'] }});
    // // console.log(paths);
    // // paths.map(function(path) {
    // //   console.log(path);
    // // })
    return { paths, fallback: 'blocking' }
}

async function getCollectionPaths(json2, slug) {
  json2.rows.map(function(row) {
    row.components.map(function(component) {
      if(component.name == 'collection') {
        component.options.items.map(function(item) {
          let slugVal = slug.split('/');
          slugVal.push(component.options.slug);
          slugVal.push(`${item.id}`);
          paths.push({ params: { 'slug': slugVal }})
        })
      }
    })
  })
}

function recursiveStaticPathGeneration(link) {
  var slugVal;
  if(typeof link.path != 'undefined') {
    slugVal = link.path
    if(slugVal != '') {
      slugVal = slugVal.split('/')
      slugVal.shift()
      // console.log(slugVal)
      paths.push({ params: { 'slug': slugVal }})
    }
  }
  if(typeof link.navigation !== 'undefined' && link.navigation.length > 0) {
    link.navigation.map(function(slink) {
      recursiveStaticPathGeneration(slink)
    })
  }
}

export default Page